import { Dispatch, createContext, useContext } from 'react';
import { WalletReducerAction } from '../reducers/walletReducer';
import Provider from '@walletconnect/ethereum-provider';

export interface WalletProps {
  provider?: Provider;
}

export const WalletContext = createContext<WalletProps | null>(null);
export const WalletDispatchContext = createContext<Dispatch<WalletReducerAction> | null>(null);

export function useWallet() {
  return useContext(WalletContext);
}

export function useWalletDispacth() {
  return useContext(WalletDispatchContext);
}
