import EventEmitter from 'eventemitter3';

export const USER_PROFILE = 'user_profile';
export const WALLET_ADDRESS = 'wallet_address';

export const EE = new EventEmitter();

export const UPDATE_POINTS = 'update_points';

export const EVENT_SHOW_LOGIN_DIALOG = 'show_login_dialog';

export const STAKE_CHAIN_ID = 'stake_chain_id';
