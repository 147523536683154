import { ReactNode, memo, useEffect, useReducer } from 'react';
import { USER_PROFILE } from '../constant/storageKey';
import {
  UserProfileActionType,
  UserProfileContext,
  UserProfileDispatchContext,
  initialState,
  userReducer,
} from '../contexts/userProfileContext';
import { UserProfile } from '../types/user';
import { getUserProfile } from '../api/user';

interface IProps {
  children: ReactNode;
}

function createUserProfile(): UserProfile | null {
  try {
    const userProfile = localStorage.getItem(USER_PROFILE);
    if (userProfile) {
      const _userProfile = JSON.parse(userProfile);
      return _userProfile;
    }
  } catch {
    return null;
  }
  return null;
}

const UserProvider = ({ children }: IProps) => {
  const [userProfile, dispatch] = useReducer(userReducer, createUserProfile() ?? initialState);

  useEffect(() => {
    if (userProfile?.user_id) {
      getUserProfile(userProfile?.user_id).then((profile) => {
        dispatch({ type: UserProfileActionType.Update, payload: profile });
      });
    }
  }, [userProfile?.user_id]);

  return (
    <UserProfileContext.Provider value={userProfile}>
      <UserProfileDispatchContext.Provider value={dispatch}>
        {children}
      </UserProfileDispatchContext.Provider>
    </UserProfileContext.Provider>
  );
};

export default memo(UserProvider);
