import { ReactNode, useReducer, memo, useEffect } from 'react';
import { WalletContext, WalletDispatchContext } from '../contexts/WalletContext';
import { walletReducer } from '../reducers/walletReducer';
import { useWalletConnecProvider } from '../hooks/useWalletConnecProvider';

interface IProps {
  children: ReactNode;
}

const WalletProvider = ({ children }: IProps) => {
  const providerType = localStorage.getItem('__provider__');
  const provider = useWalletConnecProvider();

  const [wallet, dispatch] = useReducer(walletReducer, {});

  useEffect(() => {
    dispatch?.({
      type: 'connected',
      payload: {
        provider: providerType === 'walletconnect' ? provider : window.ethereum,
      },
    });
  }, [provider, dispatch]);

  return (
    <WalletContext.Provider value={wallet}>
      <WalletDispatchContext.Provider value={dispatch}>{children}</WalletDispatchContext.Provider>
    </WalletContext.Provider>
  );
};

export default memo(WalletProvider);
