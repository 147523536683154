import { getSdnAccessToken } from '../lib/utils';
import { MyPointsRankRes, PointsRankRes, RankType } from '../types/task';
import { UserProfile } from '../types/user';
import { http } from './http';
import { getSdmAccessToken } from '../lib/utils';

export async function getUserProfile(userId: string): Promise<UserProfile> {
  const token = getSdnAccessToken();
  const _userId = encodeURIComponent(userId.toLowerCase());
  const res = await fetch(`https://beginner.sending.network/_api/client/r0/profile/${_userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.status === 200) {
    return await res.json();
  } else {
    throw res;
  }
}

export async function setDisplayname(userId: string, name: string) {
  const token = getSdnAccessToken();
  const _userId = encodeURIComponent(userId.toLowerCase());
  const res = await fetch(
    `https://beginner.sending.network/_api/client/r0/profile/${_userId}/displayname`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
      body: JSON.stringify({ displayname: name, extended_fields: { ens: 0 } }),
    }
  );
  if (res.status === 200) {
    return await res.json();
  } else {
    throw res;
  }
}

export async function getSelfPointRank(rankType: RankType): Promise<MyPointsRankRes> {
  return http.get<void, MyPointsRankRes>(`/my_points_rank`, { params: { rank_type: rankType } });
}

export async function getUserPointRank(rankType: RankType): Promise<PointsRankRes> {
  const token = getSdmAccessToken();
  return http.get<void, PointsRankRes>(`/quest/user_points_rank`, {
    params: { is_login: token ? true : false, rank_type: rankType },
  });
}
