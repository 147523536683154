import { RouterProvider } from 'react-router-dom';
import routes from './router';
import './App.css';
import UserProvider from './providers/UserProvider';
import WalletProvider from './providers/WalletProvider';
import GlobalProver from './providers/GlobalProver';

function App() {
  return (
    <UserProvider>
      <GlobalProver>
        <WalletProvider>
          <RouterProvider
            router={routes}
            fallbackElement={''}
            future={{ v7_startTransition: true }}
          />
        </WalletProvider>
      </GlobalProver>
    </UserProvider>
  );
}

export default App;
