import { trim } from 'radash';
import { USER_PROFILE } from '../../constant/storageKey';
import { UserProfile } from '../../types/user';
import { Sha256 } from '@aws-crypto/sha256-js';

type FormatWalletAddressPrarams = {
  walletAddress: string;
  replaceStr?: string;
  startCount?: number;
  endCount?: number;
};

export function formatWalletAddress({
  walletAddress,
  replaceStr = '...',
  startCount = 4,
  endCount = 4,
}: FormatWalletAddressPrarams) {
  if (walletAddress && walletAddress !== '') {
    if (walletAddress?.length <= startCount + endCount) {
      return walletAddress;
    }
    return `${walletAddress?.slice(0, startCount)}${replaceStr}${walletAddress?.slice(-endCount)}`;
  } else {
    return '';
  }
}

export function walletAddress2UserId(walletAddress: string) {
  const id = walletAddress?.replace(/^0x/, '');
  return `@sdn_${id}:${id}`;
}

export function formatNumber(target: number) {
  if (target < 1000) {
    return target;
  }
  return `${trim(target?.toString()?.replace(/([0-9]{3})$/, '.$1'), '0')}K`;
}

export function sign2Token(sign: string) {
  return Buffer.from(sign.substring(2), 'hex')
    .toString('base64')
    .replace(/(\+)/g, '-') // Convert '+' to '-'
    .replace(/\//g, '_'); // Convert '/' to '_'
}

export function getDefaultAvatar(id: string, size = 80) {
  return `https://static.sending.me/beam/${size}/${id}?colors=FC774B,FFB197,B27AFF,DAC2FB,F0E7FD&square=true`;
}

export function getSdmAccessToken() {
  try {
    const profile: UserProfile = JSON.parse(localStorage.getItem(USER_PROFILE) || '') || {};
    return profile?.homeserver_creds?.accessToken;
  } catch {
    return '';
  }
}

export function getSdnAccessToken() {
  return localStorage.getItem('mx_access_token');
  // try {
  //   const { access_token }: UserProfile =
  //     JSON.parse(localStorage.getItem(USER_PROFILE) || '') || {};
  //   return access_token || '';
  // } catch {
  //   return '';
  // }
}

const LOWERCASE = 'abcdefghijklmnopqrstuvwxyz';
const UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DIGITS = '0123456789';

function randomStringFrom(len: number, chars: string): string {
  let ret = '';

  for (let i = 0; i < len; ++i) {
    ret += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return ret;
}

export function randomString(len: number): string {
  return randomStringFrom(len, UPPERCASE + LOWERCASE + DIGITS);
}

export function generateClientSecret(): string {
  return randomString(32);
}

/**
 *
 *
 * @param avatar string
 * @returns string
 */
export const transferAvatar2minimization = (avatar: string): string => {
  if (avatar?.includes('/linx-sdn/image/thumb')) {
    return avatar;
  }
  if (avatar?.includes('/linx-sdn/image/')) {
    return avatar.replace('/linx-sdn/image/', '/linx-sdn/image/thumb/400/');
  }

  return avatar;
};

export function numberFixed(value: number | string, n: number, mathMethod?: string) {
  let res = value;
  switch (mathMethod) {
    case 'floor':
      res = Math.floor(Number(value) * Math.pow(10, n)) / Math.pow(10, n);
      break;
    case 'ceil':
      res = Math.ceil(Number(value) * Math.pow(10, n)) / Math.pow(10, n);
      break;
    default:
      res = Math.round(Number(value) * Math.pow(10, n)) / Math.pow(10, n);
      break;
  }

  return res;
}

export async function generateSalt(secretKey: string, value: string) {
  const hash = new Sha256(secretKey);
  hash.update(value);
  const res = hash.digestSync();
  const salt = Buffer.from(res).toString('base64');
  return salt;
}

export function useIdColorHash(id: string | undefined): number {
  const MIN = 1;
  const MAX = 6;

  if (typeof id !== 'string') {
    return MIN;
  }
  // Sum up the values of all the char codes in the string
  const charCodeSum = id.split('').reduce((sum, char) => {
    return sum + char.charCodeAt(0);
  }, 0);
  return (charCodeSum % MAX) + MIN;
}
