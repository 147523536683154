import { ChainListItem } from '../types/stake';
import chainBscIon from '../assets/images/chain_bsc.svg';
import chainLineaIon from '../assets/images/chain_linea.svg';
import chainEthIcon from '../assets/images/chain_eth.svg';

export const LINEA_SWAP_URL = 'https://portfolio.metamask.io/swap';
export const LINEA_BRIDGE_URL = 'https://bridge.linea.build/';
export const BSC_SWAP_URL = 'https://app.uniswap.org/swap?chain=bnb';
export const INFURA_ID = 'c3fbac6d01a74109b3c5088d657f528e';

// chains
export const SUPPORT_CHAINS = [
  //mint test
  {
    chainId: 11155111,
    name: 'Ethereum',
    explorerUrl: 'https://sepolia.etherscan.io/',
    rpcUrl: `https://sepolia.infura.io/v3/${INFURA_ID}`,
    icon: chainEthIcon,
    native_currency: { symbol: 'ETH', name: 'Ethereum', decimals: '18' },
    contract: {
      MINTNFT_ADDRESS: '0xE98BeF32d13c6799EDB50d136Af2974AE71eaF23',
    },
    stakeTokenAddress: '',
  },
  {
    chainId: 59141,
    name: 'Linea',
    explorerUrl: 'https://sepolia.lineascan.build/',
    rpcUrl: `https://rpc.sepolia.linea.build/`,
    icon: chainLineaIon,
    native_currency: { symbol: 'ETH', name: 'Ethereum', decimals: 18 },
    contract: {
      DEPOSIT_ADDRESS: '0x810331A90B24bf17db421ae54cE09f4b24930227',
      CHECKIN_ADDRESS: '0x5Ae4ebF9991a02f4376B01266Cd38C3A6c10918C',
      MINTNFT_ADDRESS: '',
    },
    stakeTokenAddress: '',
  },
  {
    chainId: 59144,
    name: 'Linea',
    explorerUrl: 'https://lineascan.build/',
    rpcUrl: `https://linea-mainnet.infura.io/v3/${INFURA_ID}`,
    icon: chainLineaIon,
    native_currency: { symbol: 'ETH', name: 'Ethereum', decimals: 18 },
    contract: {
      DEPOSIT_ADDRESS: '0x037817e94b916C326989aDB396F50d63aB8720d8',
      CHECKIN_ADDRESS: '0x540104487fbb3a88881fE6A95E24768eFe0ee380',
      MINTNFT_ADDRESS: '0xa84a1ec6ce6433ab486597d342306a61c7fe22d6',
    },
    stakeTokenAddress: '',
  },
  {
    chainId: 56,
    name: 'BSC',
    explorerUrl: 'https://bscscan.com/',
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    icon: chainBscIon,
    native_currency: { symbol: 'BNB', name: 'BNB', decimals: 18 },
    contract: {
      DEPOSIT_ADDRESS: '0x14c00E1e43Ca7D416bFB759fdD1dd51bD67C57c6',
      CHECKIN_ADDRESS: '0x6dF1a62C05126A3523DD52500a71FbfE3373Fa01',
      MINTNFT_ADDRESS: '0xcD4276A242d32fc3228B4fC9C510263bA04139e7',
    },
    stakeTokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  {
    chainId: 97,
    name: 'BSC',
    explorerUrl: 'https://testnet.bscscan.com/',
    rpcUrl: 'https://data-seed-prebsc-1-s2.binance.org:8545',
    icon: chainBscIon,
    native_currency: { symbol: 'BNB', name: 'BNB', decimals: 18 },
    contract: {
      DEPOSIT_ADDRESS: '0x21D5832Fd82dac9d0FE1B59884D9c545d342Ab60',
      CHECKIN_ADDRESS: '0xA3EFbA1B669C362182d26333591842F5c6dc31EA',
      MINTNFT_ADDRESS: '0xA2B8A7962e7921c7E000bbA135DC180f36cEf863',
    },
    stakeTokenAddress: '0x60878e7F06A8D0c0D1095b8A5CFcffF46a0D0d0A',
  },
];

export const LINEA_CHAIN = [59141, 59144];
export const BSC_CHAIN = [97, 56];

export const CHAINS_LIST: ChainListItem[] = import.meta.env.VITE_APP_STAKE_CHAINIDS?.split(
  ','
)?.map((key: string) => {
  const obj = SUPPORT_CHAINS?.find((o) => o?.chainId === Number(key));
  return {
    chainId: Number(key) ?? 0,
    name: obj?.name ?? '',
    icon: obj?.icon ?? '',
    tokenAddress: obj?.stakeTokenAddress ?? '',
  };
});

export const WALLETCONNECT_V2_RPC = {
  // eth
  1: `https://mainnet.infura.io/v3/${INFURA_ID}`,
  11155111: `https://sepolia.infura.io/v3/${INFURA_ID}`,
  // ply
  137: `https://polygon-mainnet.infura.io/v3/${INFURA_ID}`,
  // bnb
  56: `https://bsc-dataseed.binance.org/`,
  97: `https://data-seed-prebsc-1-s2.binance.org:8545/`,
  // linea
  // 59140: `https://rpc.goerli.linea.build/`,
  59141: `https://rpc.sepolia.linea.build/`,
  59144: `https://rpc.linea.build`,
};
