import { Dispatch, createContext, useContext } from 'react';
import { MintInfo } from '../types/task';
import { STAKE_CHAIN_ID } from '../constant/storageKey';
import { CHAINS_LIST } from '../constant/defaultConfig';

export interface GlobalProps {
  sltChain?: number;
  points_balance?: number;
  points_boost_rate?: number;
  points_boost_rate_type?: number;
  mint_info?: MintInfo | undefined;
}

export const GlobalContext = createContext<GlobalProps | null>(null);
export const GlobalDispatchContext = createContext<Dispatch<GlobalReducerAction> | null>(null);

export function useGlobalStore() {
  return useContext(GlobalContext);
}

export function useGlobalDispacth() {
  return useContext(GlobalDispatchContext);
}

export type GlobalReducerAction = {
  type: string;
  payload: GlobalProps;
};

export enum GlobalReducerActionType {
  Update = 'Global:Update',
}

export const initialState = {
  sltChain: localStorage.getItem(STAKE_CHAIN_ID)
    ? Number(localStorage.getItem(STAKE_CHAIN_ID))
    : CHAINS_LIST?.[0]?.chainId,
};

export const globalReducer = (
  globalProps: GlobalProps,
  action: GlobalReducerAction
): GlobalProps => {
  switch (action.type) {
    case GlobalReducerActionType.Update:
      return { ...globalProps, ...action.payload };
    default:
      return initialState;
  }
};
