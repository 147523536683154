import { ReactNode, memo, useReducer } from 'react';
import {
  GlobalContext,
  GlobalDispatchContext,
  globalReducer,
  initialState,
} from '../contexts/globalContext';

interface IProps {
  children: ReactNode;
}

const GlobalProvider = ({ children }: IProps) => {
  const [global, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={global}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalContext.Provider>
  );
};

export default memo(GlobalProvider);
